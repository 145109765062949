import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-36 h-36 cursor-pointer flex bg-gray-100 border-2 border-dashed border-gray-300" }
const _hoisted_2 = { class: "my-auto text-center w-full text-sm text-primary-500" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}